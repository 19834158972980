import React from 'react';
import Layout from '@components/Layout';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import TwitterIcon from '@material-ui/icons/Twitter';
import Grid from '@material-ui/core/Grid';
import { Link } from 'gatsby';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(10),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  card: {
    color: 'white',
    background: theme.palette.secondary.main,
    '& button': {
      color: 'white',
    },
    '& a': {
      display: 'flex',
      alignItems: 'center',
      color: '#00ACEE',
    },
  },
}));

const about = () => {
  const classes = useStyles();
  return (
    <Layout>
      <Grid item xs={12} sm={12} lg={12}>
        <Box className={classes.root}>
          <Grid item xs={10} sm={10} lg={8}>
            <Card className={classes.card}>
              <CardContent>
                <Typography align="justify" variant="body1">
                  Hi Guys I’m a software engineer working in sri lanka.
                  Currently working in the domain of api management.
                  My interest are on API Integration,
                  API Design, Web and mobile application development.
                  Feel free to follow or contact me through twitter for any questions
                </Typography>
              </CardContent>
              <CardActions>
                <Button size="small" color="primary">
                  <Link href="https://twitter.com/DushanMJS" rel="noreferrer" target="_blank">
                    <TwitterIcon />
                    <Typography align="justify" variant="body1">
                      @DushanMJS
                    </Typography>

                  </Link>
                </Button>
              </CardActions>
            </Card>
          </Grid>
        </Box>
      </Grid>
    </Layout>
  );
};

export default about;
